const SkillList = ({ skills }) => (
  <ul className="flex flex-wrap justify-center">
    {skills.map((skill) => (
      <li
        key={skill}
        className="badge badge-primary-content badge-outline m-1 w-fit h-fit text-center"
      >
        {skill}
      </li>
    ))}
  </ul>
);

export default SkillList;
