const Hobbies = () => {
  const hobbies = require("../Data/hobbies.json").hobbies;
  return (
    <div className="m-auto card p-3 md:min-w-[20rem]">
      <div className="text-lg font-bold font-serif indent-4 mb-2 underline">Some of my hobbies:</div>
      <div className="card p-3">
        <ul className="list-disc list-inside">
          {hobbies.map((hobby) => (
            <li key={hobby}>{hobby}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Hobbies;
