const AsMe = () => {
  return (
    <div className="m-auto card p-3">
      <div className="text-lg font-bold indent-4 mb-2 underline font-serif">About Me:</div>
      <div className="card p-3 md:indent-4">
        <div className="indent-8 p-2">
          Outside of software engineering, I am a lover of music. I can play
          guitar and saxophone, and i have some ability on the bassoon and piano
          as well. I am always looking to find more music to play so please do
          contact me if you want to play some music! I also enjoy video games,
          including an interest in making some of my own. Please do check some
          of these projects out as they come out, I would love feedback or
          thoughts on them.
        </div>
      </div>
    </div>
  );
};

export default AsMe;
