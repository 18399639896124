import Picture from "./About/Picture";
import AsMe from "./About/AsMe";
import Skills from "./About/Skills";
import Hobbies from "./About/Hobbies";
import AsAnEngineer from "./About/AsAnEngineer";

const AboutMe = () => {
  return (
    <div className="mx-auto max-w-6xl grid grid-cols-1 md:p-4">
      <div className="md:flex md:flex-row-reverse card bg-primary pt-4 md:px-4 text-white">
          <div className="p-4 m-auto max-w-[95%] md:max-w-[40%]">
            <Picture />
          </div>
        <div className="p-4 mx-auto grid grid-cols-1 gap-2 content-around max-w-[95%] md:max-w-[60%]">
          <AsAnEngineer />
          <Skills />
        </div>
      </div>
      <div className="md:flex md:flex-row">
        <AsMe />
        <Hobbies />
      </div>
    </div>
  );
};

export default AboutMe;
