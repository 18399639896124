import { BrowserRouter, Routes, Route } from "react-router-dom";
import useAutoTheme from "./Theme/useAutoTheme";
import Nav from "./Nav";
import Home from "./Home";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Resume from "./Resume";
import Footer from "./Footer";

function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, "");
  useAutoTheme();
  return (
    <BrowserRouter basename={basename}>
      <div className={`pb-28 md:pb-12 min-h-screen relative`}>
        <Nav />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<AboutMe />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
