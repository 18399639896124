import ContactForm from "./Contact/ContactForm";
import WhatToExpect from "./Contact/WhatToExpect";
import HorizontalLine from "../Theme/HorizontalLine";

const Contact = () => {
  return (
    <div className="max-w-6xl mx-auto card p-4">
      <h1 className="text-3xl text-center font-serif">Contact Me</h1>
      <HorizontalLine />
      <div className="flex flex-wrap">
        <div className="w-[100%] md:w-[50%]">
          <WhatToExpect />
        </div>
        <div className="w-[100%] md:w-[50%]">
          <ContactForm className="md:w-[50%]" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
