import Picture from "./Home/Picture";
import Description from "./Home/Description";
import Marquee from "./Home/Marquee";
import LatestProjects from "./Home/LatestProjects";
import LearnAbout from "./Home/LearnAbout";
import Contact from "./Home/Contact";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <Marquee />
      <div className="mx-auto max-w-6xl md:p-4">
        <div className="md:flex md:flex-row-reverse card bg-primary">
          <div className="p-4 m-auto max-w-[95%] md:max-w-[30%]">
            <Picture />
          </div>
          <div className="p-4 mx-auto grid grid-cols-1 gap-2 content-around max-w-[95%] md:max-w-[70%]">
            <Description />
            <LearnAbout />
          </div>
        </div>
        <LatestProjects />
        <Contact />
      </div>
    </div>
  );
};

export default Home;
