import Project from "./Projects/Project";
import HorizontalLine from "./Theme/HorizontalLine";

const Projects = () => {
  const projects = require("./Data/projects.json").projects;
  return (
    <div className="mx-auto card p-4 max-w-6xl">
      <h1 className="text-2xl text-center font-serif">My Software Projects</h1>
      <HorizontalLine />
      <div className="md:grid md:grid-cols-2 md:p-5 bg-primary rounded-lg">
        {projects.map((project) => (
          <Project {...project} key={project.name} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
