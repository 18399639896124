import Project from "../Projects/Project";
import HorizontalLine from "../Theme/HorizontalLine";

const LatestProjects = () => {
  const projects = require("../Data/projects.json").projects.slice(0,2);
  return (
    <div className="mx-auto card pt-4">
      <h1 className="text-3xl text-center font-serif">My Latest Projects</h1>
      <HorizontalLine />
      <div className="md:grid md:grid-cols-2 md:p-5 bg-primary rounded-lg">
        {projects.map((project) => (
          <Project {...project} key={project.name} />
        ))}
      </div>
    </div>
  );
};

export default LatestProjects;
