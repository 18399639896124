import { NavLink } from "react-router-dom";

const NavLinks = () => {
  return (
    <>
      <li>
        <NavLink to="/projects">Projects</NavLink>
      </li>
      <li>
        <NavLink to="/about">About me</NavLink>
      </li>
      <li>
        <NavLink to="/resume">Resume</NavLink>
      </li>
    </>
  );
};

export default NavLinks;
