import resume from "./NicholasFryResume.pdf";

const DownloadResume = () => {
  return (
    <a className="text-info hover:underline p-2" href={resume} download>
      Download Resume Here
    </a>
  );
};

export default DownloadResume;
