import SkillList from "../Projects/SkillList";
import HorizontalLine from "../Theme/HorizontalLine";

const Skills = () => {
  const allSkills = require("../Data/skills.json").skills;
  return (
    <div className="card p-3">
      <h1 className="text-2xl text-center font-serif">My Professional Skills</h1>
      <HorizontalLine />
      <div className="card p-4">
        {Object.keys(allSkills).map(key => 
          <div key={key}>
            <h3 className="flex justify-center underline text-xl font-serif">{key}</h3>
            <SkillList skills={allSkills[key]} />
          </div>
          )}
      </div>
    </div>
  );
};

export default Skills;
