import { useEffect } from "react";

function useAutoTheme() {
  const theme = (themeMatches) => {
    if (themeMatches) {
      document.querySelector("html").setAttribute("data-theme", "light");
    } else {
      document.querySelector("html").setAttribute("data-theme", "dark");
    }
  };
  useEffect(() => {
    theme(window.matchMedia("(prefers-color-scheme: light)").matches);
  }, []);
  window
    .matchMedia("(prefers-color-scheme: light)")
    .addEventListener("change", (event) => theme(event.matches));
}

export default useAutoTheme;
