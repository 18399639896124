const Description = () => {
  return (
    <div className="card bg-secondary shadow-lg p-3">
      <div className="text-3xl font-serif">Welcome!</div>
      <div className="card p-3 md:indent-4">
        <div className="indent-8 p-2">
          Hi, I'm Nick, an avid problem solver and learner certified in software
          engineering. I hope you enjoy taking a look at what I have made with
          my programming experience. I'm always working on new projects so keep
          an eye out for new projects.
        </div>
      </div>
    </div>
  );
};

export default Description;
