import { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [toSend, setToSend] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });
  const [sent, setSent] = useState([false, false]);
  const handleChange = (event) => {
    event.preventDefault();
    setToSend({
      ...toSend,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_PUBLIC_KEY
    );
    if (response.status === 200) {
      setSent([true, true]);
    } else {
      setSent([true, false]);
    }
    setToSend({
      from_name: "",
      from_email: "",
      message: "",
    });
  };
  return (
    <div className="card card-bordered p-4 bg-primary">
      <form className="grid grid-col-1 font-serif" onSubmit={handleSubmit}>
        <div className="grid">
          <label htmlFor="from_email" className="text-left p-2">
            Email
          </label>
          <input
            className="p-1"
            type="email"
            name="from_email"
            id="from_email"
            placeholder="your@email.com"
            value={toSend.from_email}
            required
            onChange={handleChange}
          />
        </div>
        <div className="grid">
          <label htmlFor="from_name" className="text-left p-2">
            Name
          </label>
          <input
            className="p-1"
            type="text"
            name="from_name"
            id="from_name"
            placeholder="First Last"
            value={toSend.from_name}
            required
            onChange={handleChange}
          />
        </div>
        <div className="grid">
          <label htmlFor="message" className="text-left p-2">
            Message
          </label>
          <textarea
            className="p-1"
            type="textarea"
            name="message"
            id="message"
            placeholder="Put your message here."
            value={toSend.message}
            required
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-info mt-4 justify-self-center font-sans">
          Send
        </button>
      </form>
      {sent[0] && sent[1] ? (
        <div className="alert alert-success mx-auto my-auto">
          Email has been sent
        </div>
      ) : (
        sent[0] && (
          <div className="alert alert-error mx-auto my-auto">
            Email not sent
          </div>
        )
      )}
    </div>
  );
};

export default ContactForm;
