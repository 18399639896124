import { Link } from "react-router-dom";

const LearnAbout = () => {
  return (
    <div className="bg-secondary p-4 grid grid-cols-1 card shadow-lg">
      <div className="text-3xl font-serif">Learn More About Me</div>
      <div className="indent-8 p-2">
        I am always looking for professional and personal project opportunities,
        so if you are curious about what I am up to just check out my Projects
        page. If you want to learn more about my skill set as a software
        engineer, or you just want to learn more about who I am, check out my
        About Me page or click on the link below!
      </div>
      <div className="justify-self-center">
        <Link to="/about" className="btn btn-info">
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default LearnAbout;
