import ResumePDF from "./Resume/ResumePDF";
import DownloadResume from "./Resume/DownloadResume";
import HorizontalLine from "./Theme/HorizontalLine";

const Resume = () => {
  return (
    <div className="max-w-6xl text-center min-h-full card p-4 mx-auto">
      <h1 className="text-2xl text-center">My Resume</h1>
      <HorizontalLine />
      <ResumePDF />
      <DownloadResume />
    </div>
  );
};

export default Resume;
