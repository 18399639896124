import aboutPicture from "./Professional.jpg";

const Picture = () => {
  return (
    <img
      className="rounded-lg"
      src={aboutPicture}
      alt="Professional"
    />
  );
};

export default Picture;
