const Footer = () => {
  return (
    <>
      <footer className="footer absolute bottom-0">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:justify-between">
          <span className="text-md text-center">Nicholas Fry</span>
          <ul className="flex flex-wrap items-center text-md mt-0">
            <li>
              <a
                href="https://gitlab.com/NickFGit"
                className="mr-4 md:mr-6 hover:underline hover:text-info"
              >
                gitlab.com/NickFGit
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/frynicholas/"
                className="mr-4 md:mr-6 hover:underline hover:text-info"
              >
                linkedin.com/in/frynicholas/
              </a>
            </li>
            <li className="mr-4 md:mr-6">
              <div>frync@icloud.com</div>
            </li>
            <li className="mr-4 md:mr-6">
              <div>(303) 641-0700</div>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
