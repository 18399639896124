const WhatToExpect = () => {
  return (
    <div className="card p-3">
      <div className="text-lg font-bold indent-4 font-serif">What to expect:</div>
      <div className="card p-3 md:indent-4">
        <div className="indent-8 p-2">
          After you reach out to me through email I will be able to respond in
          less than a business day. You can also leave me a call instead of
          sending an email. If for some reason I am unable to answer, just 
          leave your name, number, and your reason for calling so that
          I can get back to you as soon as possible.
        </div>
      </div>
      <div className="text-lg font-bold indent-4 font-serif">Find me at:</div>
      <div className="card p-3 md:indent-4">
        <ul className="list-disc list-inside">
          <li>
            <a
              href="https://gitlab.com/NickFGit"
              className="mr-4 md:mr-6 hover:underline hover:text-info"
            >
              gitlab.com/NickFGit
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/frynicholas/"
              className="mr-4 md:mr-6 hover:underline hover:text-info"
            >
              linkedin.com/in/frynicholas/
            </a>
          </li>
          <li className="mr-4 md:mr-6">frync@icloud.com</li>
          <li className="mr-4 md:mr-6">(303) 641-0700</li>
        </ul>
      </div>
    </div>
  );
};

export default WhatToExpect;
