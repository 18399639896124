const Marquee = () => {
  return (
    <div className="relative flex w-screen">
      <div className="py-6 animate-marquee whitespace-nowrap">
        <span className="text-4xl mx-4">Welcome to my portfolio website!</span>
        <span className="text-4xl mx-4">Based in Colorado</span>
        <span className="text-4xl mx-4">Software Engineer</span>
        <span className="text-4xl mx-4">Self Proclaimed Musician</span>
      </div>
      <div className="absolute top-0 py-6 animate-marquee2 whitespace-nowrap">
        <span className="text-4xl mx-4">Welcome to my portfolio website!</span>
        <span className="text-4xl mx-4">Based in Colorado</span>
        <span className="text-4xl mx-4">Software Engineer</span>
        <span className="text-4xl mx-4">Self Proclaimed Musician</span>
      </div>
    </div>
  );
};

export default Marquee;
