import resume from "./NicholasFryResume.jpg";

const ResumePDF = () => {
  return (
    <div className="m-auto md:max-w-[75%] text-left card bg-primary p-4">
      <img src={resume} alt="Resume" />
    </div>
  );
};

export default ResumePDF;
