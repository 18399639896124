import { Link } from "react-router-dom";
import HorizontalLine from "../Theme/HorizontalLine";

const AsAnEngineer = () => {
  return (
    <div className="card p-3">
      <div className="text-2xl font-bold font-serif text-center">As an Engineer</div>
      <HorizontalLine />
      <div className="card p-3 md:indent-4">
        <div className="indent-8 p-2">
          I am dedicated to what I do as an engineer, and I find great pride
          with what I create. As a result of always working and learning, this
          may not be a complete list of what I currently know, but I still hope
          you can check out some of my skills and projects.
        </div>
      </div>
      <Link to="/projects" className="btn btn-info mt-4">
        Check out my Projects!
      </Link>
    </div>
  );
};

export default AsAnEngineer;
