import SkillList from "./SkillList";
import HorizontalLine from "../Theme/HorizontalLine";

const Project = ({ name, start, end, description, repo, live, skillset }) => {
  return (
      <div key={name} className="m-4 card bg-secondary w-fill h-fill">
        <div className="card-body">
          <div className="card-title justify-center font-serif">{name}</div>
          <div className="text-center">
            {start} - {end ? end : "Today"}
          </div>
          <HorizontalLine />
          <section>
            <div className="font-semibold font-serif">Description:</div>
            <p className="indent-4">{description}</p>
          </section>
          <section>
            <div className="font-semibold font-serif">Skills Used:</div>
            <SkillList skills={skillset} />
          </section>
          {repo && (
            <section>
              <div className="font-semibold font-serif">Git Repository:</div>
              <p className="indent-4">
                <a href={repo} className="underline text-info font-serif">
                  {repo}
                </a>
              </p>
            </section>
          )}
          {live && (
            <section>
              <div className="font-semibold font-serif">Live address:</div>
              <p className="indent-4">
                <a href={live} className="underline text-info font-serif">
                  {live}
                </a>
              </p>
            </section>
          )}
        </div>
      </div>
  );
};

export default Project;
